import ProtectedRoute from "hub/src/components/auth/ProtectedRoute";
import LoadingPage from "hub/src/components/common/LoadingPage";
import useDeviceInfo from "hub/src/hooks/useDeviceInfo";
import useHubSessionContext from "hub/src/hooks/useHubSessionContext";
import AccessDeniedPage from "hub/src/pages/AccessDeniedPage";
import AccountRecoverPage from "hub/src/pages/AccountRecoverPage";
import CheckoutPage from "hub/src/pages/CheckoutPage";
import FormPage from "hub/src/pages/FormPage";
import LoginPage from "hub/src/pages/LoginPage";
import NativeAppPDFViewerPage from "hub/src/pages/NativeAppPDFViewerPage";
import PageNotFound from "hub/src/pages/PageNotFound";
import ReviewsVotingPage from "hub/src/pages/reviews/ReviewsVotingPage";
import SharedUrlsPage from "hub/src/pages/SharedUrlsPage";
import NewWorkflowPage from "hub/src/pages/workflow/new/complexWorkflowId";
import RouteConfigWrapper, {
  HubRouteConfig,
} from "hub/src/routes/RouteConfigWrapper";
import { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router";

const conferencePageMapper: Record<
  string,
  React.ComponentType<HubRouteConfig>
> = {
  conferenceLandingPage: lazy(
    () => import("hub/src/pages/conference/ConferenceLandingPage"),
  ),
  conferenceFilesPage: lazy(
    () => import("hub/src/pages/conference/ConferenceFilesPage"),
  ),
  conferenceSchedulePage: lazy(
    () => import("hub/src/pages/conference/ConferenceSchedulePage"),
  ),
};
//TODO:  Lazy loading the dynamic routes we have to type the page props as any since we don't know what the route params will be yet
//The components can be typed properly, with the mapper but since its dynamic its not really necessary?
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type HubRouteConfigAnyOkayHere = any;
const pageComponentMapper: Record<
  string,
  React.ComponentType<HubRouteConfig<HubRouteConfigAnyOkayHere>>
> = {
  overviewPage: lazy(() => import("hub/src/pages/OverviewPage")),
  educationPage: lazy(() => import("hub/src/pages/EducationPage")),
  financePage: lazy(() => import("hub/src/pages/finance/FinancePage")),
  invoicePage: lazy(() => import("hub/src/pages/finance/InvoicePage")),
  communicationPage: lazy(() => import("hub/src/pages/CommunicationPage")),
  myFilesPage: lazy(() => import("hub/src/pages/MyFilesPage")),
  directoriesPage: lazy(() => import("hub/src/pages/DirectoriesPage")),
  sharedUrlsPage: lazy(() => import("hub/src/pages/SharedUrlsPage")),
  ssoPage: lazy(() => import("hub/src/pages/sso/SSOPage")),
  submissionsPage: lazy(
    () => import("hub/src/pages/submissions/SubmissionsPage"),
  ),
  reviewsPage: lazy(() => import("hub/src/pages/reviews/ReviewInstancePage")),
  formPage: lazy(() => import("hub/src/pages/FormPage")),
  reviewsVotingPage: lazy(
    () => import("hub/src/pages/reviews/ReviewsVotingPage"),
  ),
  reportPage: lazy(() => import("hub/src/pages/ReportPage")),
  ...conferencePageMapper,
};

const UserHubRoutes = () => {
  const session = useHubSessionContext();
  const device = useDeviceInfo();

  if (session.society === null) {
    return <LoadingPage />;
  }

  return (
    <Suspense fallback={<LoadingPage />}>
      <Switch>
        <Route exact path="/login">
          <RouteConfigWrapper>
            <LoginPage />
          </RouteConfigWrapper>
        </Route>
        {!session.societyUserId && (
          <Route exact path="/">
            <RouteConfigWrapper>
              <Redirect to="/login" />
            </RouteConfigWrapper>
          </Route>
        )}
        <Route exact path="/access-denied">
          <RouteConfigWrapper>
            <AccessDeniedPage />
          </RouteConfigWrapper>
        </Route>

        {session.society?.routes?.flatMap((route) => {
          const allRoutes = [route];

          if (
            route.childrenAppRouteConfig?.length &&
            route.childrenAppRouteConfig?.length > 0
          ) {
            route.childrenAppRouteConfig.forEach((childRoute) => {
              allRoutes.push({ ...childRoute, parentAppRouteConfig: route });
            });
          }

          return allRoutes.map((nestedRoute) => {
            if (!nestedRoute.externalRoute) {
              const Component = pageComponentMapper[nestedRoute.componentName!];

              // Construct the page component with the route params overwriting the dynamic params with configured ones

              if (
                nestedRoute.everyTag?.length === 0 &&
                nestedRoute.someTag?.length === 0 &&
                nestedRoute.noneTag?.length === 0
              ) {
                // If there are no tags, it is a public route
                return (
                  <Route
                    exact
                    path={nestedRoute.url}
                    render={() => (
                      <RouteConfigWrapper routeConfig={nestedRoute}>
                        <Component />
                      </RouteConfigWrapper>
                    )}
                    key={nestedRoute.id}
                  />
                );
              } else {
                return (
                  <ProtectedRoute
                    exact
                    path={nestedRoute.url}
                    key={nestedRoute.id}
                    render={() => (
                      <RouteConfigWrapper routeConfig={nestedRoute}>
                        <Component />
                      </RouteConfigWrapper>
                    )}
                  />
                );
              }
            }
            return null;
          });
        })}

        {/* {session.society?.societySettingsPublic?.ablyEnabled && (
        <Route exact path="/chats/:id">
          <RouteConfigWrapper>
            <ChatPage />
          </RouteConfigWrapper>
        </Route>
      )} */}
        <ProtectedRoute exact path="/form/:formId">
          <RouteConfigWrapper>
            <FormPage />
          </RouteConfigWrapper>
        </ProtectedRoute>
        <ProtectedRoute exact path="/shared-urls/:fileId">
          <RouteConfigWrapper>
            <SharedUrlsPage />
          </RouteConfigWrapper>
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path="/submission/:submissionDefinitionId/instance/:submissionInstanceId"
        >
          <RouteConfigWrapper>
            <FormPage />
          </RouteConfigWrapper>
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path="/reviews/:reviewSessionId/assignment/:reviewInstanceAssignmentId"
        >
          <RouteConfigWrapper>
            <ReviewsVotingPage />
          </RouteConfigWrapper>
        </ProtectedRoute>

        {device.isWeb && (
          <ProtectedRoute exact path="/payment/:invoiceId">
            <RouteConfigWrapper>
              <CheckoutPage />
            </RouteConfigWrapper>
          </ProtectedRoute>
        )}

        <ProtectedRoute exact path="/workflow/new/:workflowId">
          <RouteConfigWrapper>
            <NewWorkflowPage />
          </RouteConfigWrapper>
        </ProtectedRoute>
        <Route exact path="/account/new">
          <RouteConfigWrapper>
            <FormPage />
          </RouteConfigWrapper>
        </Route>
        <Route exact path="/account/recover">
          <RouteConfigWrapper>
            <AccountRecoverPage />
          </RouteConfigWrapper>
        </Route>
        <Route exact path="/pdf-viewer/:pdfData">
          <RouteConfigWrapper>
            <NativeAppPDFViewerPage />
          </RouteConfigWrapper>
        </Route>
        <Route>
          <RouteConfigWrapper>
            <PageNotFound />
          </RouteConfigWrapper>
        </Route>
      </Switch>
    </Suspense>
  );
};

export default UserHubRoutes;
